<template>
    <div>
      <!-- 상단 아이콘 제거 -->
      <div class="card" style="width: 100%;">


        </div>
      <!--  장기 결석자 -->
      <!-- <div class="card" style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title" style="color: darkred;">장기 결석자<span style="font-size: 11pt;">(3주 이상)</span></h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./notice')"></h6>
          <p class="card-text">
            <table class="table">             
                <tbody>
                  <tr >
                    <td v-if="grade =='99'">
                      <span  >
                        <ul class="list-group" v-for="mlist in groupedItems" v-bind:key="mlist.no">
                          <li class="list-group-item" style="margin-bottom: 7px;">{{mlist[0]["gradename"]}} : <span v-for="slist in mlist" v-bind:key="slist.name">&nbsp;&nbsp;{{slist.name}}</span></li>
                        </ul>


                      </span>                      
                     </td>
                     <td v-else>
                      <span v-for="mlist in absentlist" v-bind:key="mlist.no">{{ mlist.name }}&nbsp;&nbsp;</span>                      
                     </td>                     
                  </tr>               
                </tbody>
              </table>
  
          </p>
        </div>
      </div> -->
      <p></p>
<!--  마을 공지 -->
      <div class="card" style="width: 100%;">
        <div class="card-body" style="font-size: 14px;">
          <h5 class="card-title" style="font-size: 16px;"><i class="fa-solid fa-volume-up"></i>  마을 공지</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./notice')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>                 
                <thead class="table-dark">
                  <tr>
                    <th scope="col-sm-1">No</th>
                    <th scope="col-sm-10">제목</th>
                    <th scope="col-sm-1" >일자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in nlists" v-bind:key="list.no" @click="detailView(list)">
                    <th scope="row">{{idx+1}}</th>
                    <td>{{ list.subject.substring(0,30) }}</td>
                    <td>{{ formatDate(list.wdate) }}</td>
                  </tr>               
                </tbody>
              </table>
  
          </p>
        </div>
      </div>
      <p></p>      

      <!-- 스케줄 달력 -->
      <div class="card" style="width: 100%; margin-bottom: 20px;">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="card-title" style="margin: 0;">
              <i class="bi bi-calendar3"></i> 마을 일정표
            </h5>
            <button class="btn btn-primary btn-sm" @click="openScheduleModal()" v-if="grade > 4">
              <i class="bi bi-plus"></i> 일정 추가
            </button>
          </div>

          <!-- 달력 네비게이션 -->
          <div style="width: 100%; display: flex; justify-content: space-around; align-items: center; margin-bottom: 15px;">
            <button class="btn btn-link" @click="changeMonth(-1)"><i class="bi bi-chevron-left"></i></button>
            <h5 style="margin: 0;">{{ currentYear }}년 {{ currentMonth }}월</h5>
            <button class="btn btn-link" @click="changeMonth(1)"><i class="bi bi-chevron-right"></i></button>
          </div>

          <!-- 달력 -->
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>일</th>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="week in calendar" :key="week[0].date">
                <td v-for="day in week" :key="day.date" 
                    :class="{'text-muted': !day.isCurrentMonth, 'has-schedule': day.schedules.length > 0}"
                    @click="showDaySchedules(day)">
                  <div class="date-cell">
                    {{ day.dayNumber }}
                    <div v-if="day.schedules.length > 0" class="schedule-dots">
                      <span v-for="(schedule, idx) in day.schedules.slice(0, 3)" 
                            :key="idx" 
                            class="schedule-dot"
                            :style="{ backgroundColor: getCategoryColor(schedule.category) }">
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>             
      <!-- 전체 공지 -->
      <p></p>
      <div class="card" style="width: 100%;">
        <div class="card-body" style="font-size: 14px;">
          <h5 class="card-title" style="font-size: 16px;"><i class="fa-solid fa-bell"></i> 전체 공지</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./allnotice')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>                 
                <thead class="table-dark">
                  <tr>
                    <th scope="col-sm-1">No</th>
                    <th scope="col-sm-10">제목</th>
                    <th scope="col-sm-1" >일자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in allists" v-bind:key="list.no" @click="detailView(list)">
                    <th scope="row">{{idx+1}}</th>
                    <td>{{ list.subject.substring(0,30) }}</td>
                    <td>{{ formatDate(list.wdate) }}</td>
                  </tr>               
                </tbody>
              </table>
  
          </p>
        </div>
      </div>
      <p></p>      
      <div class="card" style="width: 100%;">
        <div class="card-body" style="font-size: 14px;">
          <h5 class="card-title" style="font-size: 16px;"><i class="fa-solid fa-file-import"></i> 셀자료</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./study')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>              
              <thead class="table-dark">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">제목</th>
                  <th scope="col">일자</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, idx)  in slists" v-bind:key="list.no" @click="detailView(list)">
                  <th scope="row">{{idx + 1}}</th>
                  <td>{{ list.subject.substring(0,30) }}</td>
                  <td>{{ formatDate(list.wdate) }}</td>
                </tr>            
              </tbody>
            </table>  
          </p>
        </div>
      </div>
      <p></p>
      <p></p>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ items.subject }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- v-if로 items가 존재할 때만 modal-body를 렌더링 -->
      <div class="modal-body">
                      <div v-if="items && items.contents" v-html="getContent(items.contents)"></div>
                      <!-- Display file download link if a file is present -->
                      <template v-if="items.files">
                          <a :href="getFileUrl(items.files)" download>
                              다운로드 파일
                          </a>
                        </template> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
      </div>
    </div>
  </div>
</div>

<!-- 일정 모달 -->
<div class="modal fade" id="scheduleModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ isEditMode ? '일정 수정' : '일정 추가' }}</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>카테고리</label>
          <select class="form-control" v-model="scheduleForm.category">
            <option value="">카테고리 선택</option>
            <option value="예배">예배(부서)</option>
            <option value="교육">교육(양육)</option>
            <option value="행사">행사(프로그램)</option>
            <option value="계획">연간(월간)계획</option>
            <option value="기타">기타</option>
          </select>
        </div>
        <div class="form-group">
          <label>제목</label>
          <input type="text" class="form-control" v-model="scheduleForm.title">
        </div>
        <div class="form-group">
          <label>날짜</label>
          <input type="date" class="form-control" v-model="scheduleForm.date">
        </div>
        <div class="form-group">
          <label>내용</label>
          <textarea class="form-control" v-model="scheduleForm.contents" rows="3"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
        <button v-if="isEditMode && grade > 4" type="button" class="btn btn-danger" @click="deleteSchedule">삭제</button>
        <button type="button" v-if="grade > 4" class="btn btn-primary" @click="saveSchedule">저장</button>
      </div>
    </div>
  </div>
</div>

<!-- 일정 목록 모달 -->
<div class="modal fade" id="scheduleListModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ selectedDate }} 일정</h5>
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="selectedDaySchedules.length === 0" class="text-center">
          등록된 일정이 없습니다.
        </div>
        <div v-else class="list-group">
          <a href="#" class="list-group-item list-group-item-action" 
             v-for="schedule in selectedDaySchedules" 
             :key="schedule.no"
             @click="editSchedule(schedule)">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-1">
                <span class="badge" :style="{ backgroundColor: getCategoryColor(schedule.category) }">
                  {{ schedule.category }}
                </span>
                {{ schedule.title }}
              </h6>
              <small>{{ schedule.writer }}</small>
            </div>
            <p class="mb-1" style="white-space: pre-line;">{{ schedule.contents }}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  </template>
  
  <script>
    import manageService from "../services/manageService";
    import dayjs from 'dayjs';
    import $ from "jquery";    
    import '@fortawesome/fontawesome-free/css/all.css';
    import '@fortawesome/fontawesome-free/js/all.js';

    import { getMessaging, getToken  } from "firebase/messaging";

    export default {
        component: {
            dayjs
        },        
      data() {
        return {
          items: {}, // 빈 객체로 초기화          
          nlists: {},
          slists: {},   
          allists: {},       
          id: this.$store.state.id,
          grade: this.$store.state.grade,
          phone: this.$store.state.phone,
          name: this.$store.state.name,
          teacherid: this.$store.state.teacherid,
          role: this.$store.state.role,
          token: this.$store.state.token,  
          classid: this.$store.state.classid,    
          absentlist: {},    
          absentlist2: {},        
          currentYear: new Date().getFullYear(),
          currentMonth: new Date().getMonth() + 1,
          calendar: [],
          schedules: [],
          scheduleForm: {
            no: 0,
            title: '',
            contents: '',
            date: '',
            category: '',
            userid: this.$store.state.id,
            classid: this.$store.state.classid
          },
          isEditMode: false,
          selectedDate: '',
          selectedDaySchedules: []
        }
      },
      computed:{
        isAuthenticated() {
          return this.$store.state.isAuthenticated;
         },        
        formatDate(){
            return (v)=>{
              return dayjs(v).format("YY.MM.DD");
              }
           },          
           groupedItems() {
            const result = Array.isArray(this.absentlist2);

            if(result){
              return this.absentlist2.reduce((groups, item) => {
                const group = groups[item.gradename] || [];
                group.push(item);
                groups[item.gradename] = group;
                return groups;
              }, {});
            } else {
              return null;
            }


          }           
     },          
      methods:{
        getContent(text){
            if(text){
                return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
            }
        },          
            detailView(item){
              if (item) {
                this.items = item;
                //console.log(this.items); // 콘솔에 items 데이터 출력
                $("#exampleModal").modal();
              }
            },
            getData(){
              const data = {
                classid: this.classid,
              };
              manageService
                  .getboardDashboard(data)
                  .then((response) => {

                  this.nlists = response.data.notice;
                  this.slists = response.data.study;
                  this.allists = response.data.allnotice;
                  })
                  .catch((e) => {
                  console.log(e);
                  });
            },

            getAbsent(){
              const data = {
                classid: this.classid,
                grade: this.grade,
              };
              manageService
                  .getAbsentAll(data)
                  .then((response) => {
                    //console.log(response);
                  this.absentlist = response.data;     
                  this.absentlist2 = response.data; 
                  })
                  .catch((e) => {
                  console.log(e);
                  });
            },
          RegistFcmToken(){
            // FCM 설정
            const messaging = getMessaging();

            getToken(messaging, { vapidKey: 'BKUbGoOe9UBTxIbCSKuQnACE25UGZ5J5rV11qE6nAuNVgiythp5-oymMgM9RcX8r6iTNA6T_77Cg13Bq-xReuBE' }).then((currentToken) => {
              if (currentToken) {                
                // FCM 토큰을 서버로 전송하거나 필요에 따라 처리

                const data = {
                 phone: this.phone,
                 token: currentToken,
                };
              manageService
                  .setFcmToken(data)
                  .then(() => {
                    //console.log("setFcmToken==", response);
                  })
                  .catch((e) => {
                  console.log(e);
                  });

              } else {
                console.warn('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.error('An error occurred while retrieving token. ', err);
            });
          },
          getFileUrl(fileName) {

                let uploadDir = "";
                const serverAddress = '119.205.233.48';
                  // Determine upload directory based on server address
                  if (window.location.hostname === serverAddress) {
                    uploadDir = 'https://manna.donganyouth.com/upload/files/'; // Linux server path
                  } else {
                    //uploadDir =  'D:\\work\\vue_work\\youthmanna\\www\\upload\\files\\'; // Windows server path
                    uploadDir = 'https://manna.donganyouth.com/upload/files/'; // Linux server path
                  }

                  // Replace with your actual upload directory path
                  //let uploadDir = '/path/to/your/upload/directory/';

                  // Construct full file path
                  let fileUrl = uploadDir + fileName;

                  return fileUrl;
                },             
        initCalendar() {
            const firstDay = new Date(this.currentYear, this.currentMonth - 1, 1);
            const lastDay = new Date(this.currentYear, this.currentMonth, 0);
            const startDay = new Date(firstDay);
            startDay.setDate(startDay.getDate() - startDay.getDay());

            this.calendar = [];
            let currentWeek = [];

            for (let d = new Date(startDay); d <= lastDay || currentWeek.length > 0; d.setDate(d.getDate() + 1)) {
                if (currentWeek.length === 7) {
                    this.calendar.push(currentWeek);
                    currentWeek = [];
                }

                const isCurrentMonth = d.getMonth() === this.currentMonth - 1;
                const daySchedules = this.schedules.filter(s => 
                    dayjs(s.date).format('YYYY-MM-DD') === dayjs(d).format('YYYY-MM-DD')
                );

                currentWeek.push({
                    date: dayjs(d).format('YYYY-MM-DD'),
                    dayNumber: d.getDate(),
                    isCurrentMonth,
                    schedules: daySchedules
                });

                if (d > lastDay && currentWeek.length === 7) {
                    this.calendar.push(currentWeek);
                    break;
                }
            }
        },

        changeMonth(delta) {
            let newMonth = this.currentMonth + delta;
            let newYear = this.currentYear;

            if (newMonth > 12) {
                newMonth = 1;
                newYear++;
            } else if (newMonth < 1) {
                newMonth = 12;
                newYear--;
            }

            this.currentMonth = newMonth;
            this.currentYear = newYear;
            this.getSchedules();
        },

        getSchedules() {
            const data = {
                classid: this.classid,
                year: this.currentYear,
                month: this.currentMonth
            };

            manageService.getScheduleList(data)
                .then(response => {
                    this.schedules = response.data;
                    this.initCalendar();
                })
                .catch(error => {
                    console.error('일정 조회 실패:', error);
                });
        },

        openScheduleModal(date = '') {
            this.isEditMode = false;
            this.scheduleForm = {
                no: 0,
                title: '',
                contents: '',
                date: date || dayjs().format('YYYY-MM-DD'),
                category: '',
                userid: this.$store.state.id,
                classid: this.$store.state.classid
            };
            console.log("scheduleForm openScheduleModal",this.scheduleForm);
            $('#scheduleModal').modal('show');
        },

        saveSchedule() {
            // 필수 입력값 검증 로직 개선
            if (!this.scheduleForm.title.trim()) {
                this.$toast.show("제목을 입력해주세요.", {
                  type: "warning",
                  position: "top-right",   
                });                
                return;
            }
            if (!this.scheduleForm.date) {
                this.$toast.show("날짜를 선택해주세요.", {
                  type: "warning",
                  position: "top-right",   
                });                
                return;
            }
            if (!this.scheduleForm.category.trim()) {
                this.$toast.show("카테고리를 선택해주세요.", {
                  type: "warning",
                  position: "top-right",   
                });                
                return;
            }

            console.log("scheduleForm",this.scheduleForm);
            manageService.saveSchedule(this.scheduleForm)
                .then(response => {
                    if (response.data[0]) {
                      this.$toast.show("일정이 저장되었습니다", {
                        type: "success",
                        position: "top-right",   
                      });                       
                        $('#scheduleModal').modal('hide');
                        this.getSchedules();
                    } else {
                        alert(response.data[1] || '일정 저장에 실패했습니다.');
                    }
                })
                .catch(error => {
                    console.error('일정 저장 실패:', error);
                    this.$toast.show("일정 저장에 실패했습니다.", {
                      type: "warning",
                      position: "top-right",   
                    });                      
                });
        },

        deleteSchedule() {
            if (!confirm('일정을 삭제하시겠습니까?')) return;

            const data = {
                no: this.scheduleForm.no,
                classid: this.classid
            };

            manageService.deleteSchedule(data)
                .then(response => {
                    if (response.data[0]) {
                        this.$toast.show("일정이 삭제되었습니다", {
                        type: "success",
                        position: "top-right",   
                      });                          
                        $('#scheduleModal').modal('hide');
                        this.getSchedules();
                    } else {
                        alert(response.data[1] || '일정 삭제에 실패했습니다.');
                    }
                })
                .catch(error => {
                    console.error('일정 삭제 실패:', error);
                    this.$toast.show("일정 삭제에 실패했습니다.", {
                      type: "warning",
                      position: "top-right",   
                    });                     
                });
        },

        showDaySchedules(day) {
            this.selectedDate = day.date;
            this.selectedDaySchedules = day.schedules;
            
            if (this.grade > 4) {
                if (day.schedules.length > 0) {
                    $('#scheduleListModal').modal('show');
                } else {
                    this.openScheduleModal(day.date);
                }
            } else {
                if (day.schedules.length > 0) {
                    $('#scheduleListModal').modal('show');
                } else {
                    this.$toast.show("등록된 일정이 없습니다.", {
                        type: "info",
                        position: "top-right",   
                    });
                }
            }
        },

        editSchedule(schedule) {
            this.isEditMode = true;
            this.scheduleForm = { 
                ...schedule,
                classid: this.$store.state.classid  // classid 추가
            };
            $('#scheduleListModal').modal('hide');
            $('#scheduleModal').modal('show');
        },

        getCategoryColor(category) {
            const colors = {
                '예배': '#FF6B6B',
                '교육': '#4ECDC4',
                '행사': '#45B7D1',
                '계획': '#96CEB4',
                '기타': '#FFEEAD'
            };
            return colors[category] || '#666666';
        }
      },
      created() {
        this.getData();   
        this.getAbsent();   
        this.RegistFcmToken();
        this.getSchedules();
        //console.log("getisLogin",this.$store.state);  
    },      
    }

  </script>
  <style scoped>
    .table-dark {
    color: #000;
    background-color: #007bff80;
   }
   .table td, .table th {
    padding: 0.2rem;
   }
   .icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.icon img {
  margin-bottom: 10px;
}

/* 달력 스타일 */
.date-cell {
    min-height: 80px;
    position: relative;
    padding: 5px;
}

.has-schedule {
    background-color: #f8f9fa;
}

.schedule-dots {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: flex;
    gap: 3px;
}

.schedule-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.badge {
    color: white;
    margin-right: 8px;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
    .date-cell {
        min-height: 50px;
    }
}
  </style>